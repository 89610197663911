@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

@font-face {
	font-family: 'Varsity';
	src: url('../../dist/font/VarsityRegular.woff2') format('woff2'),
			url('../../dist/font/VarsityRegular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: $sans;
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth; 

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

p,ul,h2,h3,h4{
	margin: 0;
}

p,li{
	line-height: 1.6;
	font-size: 16px;
}

.social{
	@include flex(row,center,center);
	gap: 10px;
	a{
		img{
			width: 30px;
		}
	}
}

.btn{
	padding: 8px 40px;
	display: inline-block;
	background-color: transparent;
	border: solid 1px transparent;
	font-size: 19px;
	line-height: 1;
	font-family: $display;
	&.btn-green{
		background-color: $green;
		color: $white;
		@include hover-focus{
			color: $black
		}
	}
	&.btn-white{
		background-color: $white;
		color: $green;
		@include hover-focus{
			color: $black
		}
	}
}

.split{
	@include flex(column,initial,initial);
	@include tablet-up{
		@include flex(row,initial,initial);
	}
}

.bg-black{
	background-color: $black;
	color: $white;
}

.bg-white{
	background-color: $white;
	color: $black;
}

.bg-green{
	background-color: $green;
	background-image: url('../../dist/img/bg-green.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	color: $white;
}

.hide{
	display: none !important;
}

.disabled{
	pointer-events: none;
}

header {
	transition: all .4s;
	*{
		transition: all .4s;
	}
	background-color: $white;
	padding: 10px 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	.container{
		.nav{
			transition: all .4s;
			transform: translateX(-100%);
			list-style: none;
			padding: 0;
			background-color: $green;
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			@include flex(column,center,center);
			li{
				a{
					display: block;
					text-align: center;
					padding: 20px;
					font-family: $display;
					text-transform: uppercase;
					color: $black;
					font-size: 24px;
					color: $white;
				}
			}
			&.active{
				transform: translateX(0);
			}
		}
		.menu-trigger{
			order: 2;
			display: block;
			border: none;
			background-color: transparent;
			border: none;
			border-style: none;
			border: solid 4px transparent;
			border-top-color: $green;
			border-bottom-color: $green;
			position: relative;
			width: 40px;
			height: 25px;
			transition: all .4s;
			position: absolute;
			top: 10px;
			right: 10px;
			z-index: 99;
			&:before,&::after{
				content: '';
				display: block;
				width: 100%;
				height: 4px;
				top: calc(50% - 2px);
				left: 0;
				position: absolute;
				background-color: $green;
				transition: all .4s;
			}
			&.active{
				border-color: transparent;
				&::after{
					background-color: $white;
					transform: rotate(45deg);
				}
				&::before{
					background-color: $white;
					transform: rotate(-45deg);
				}
			}
		}
	}

	@include tablet-up{
		padding: 0;
		.container{
			.split{
				.menu-trigger{
					display: none;
				}
				gap: 20px;
				align-items: center;
				.side{
					max-width: max-content;
					flex: 1;
					.nav{
						transition: all 0s;
						@include flex(row,center,center);
						gap: 10px;
						position: unset;
						transform: translateX(0);
						background-color: transparent;
						li{
							a{
								display: inline-block;
								color: $black;
								padding: 0 10px;
								&.btn{
									padding: 6px 20px;
									color: $white;
								}
								@include hover-focus{
									background-color: $green;
									color: $white;
								}
							}
						}
					}
					&:nth-child(2){
						flex: 3;
					}
				}
			}
		}
	}
	&.sticky{
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
		background-color: $white;
		border-bottom: solid 1px #f3f3f3;
		.container{
			.split{
				justify-content: space-between;
				.side{
					.logo{
						width: 120px;
					}
					.nav{
						li{
							a{
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}
}

.slick-arrow{
	position: absolute;
	top: calc(50% - 20px);
	font-size: 40px;
	background-color: transparent;
	color: $white;
	border: none;
	padding: 0;
	cursor: pointer;
	z-index: 99;
	&.slick-prev{
		left: 0;
	}
	&.slick-next{
		right: 0;
	}
}

main {

	section{
		padding: 60px 0;

		h2{
			&.heading{
				margin-bottom: 40px;
				font-size: 60px;
				text-transform: uppercase;
				font-family: $display;
				font-weight: 400;
				color: transparent;
				&.white{
					-webkit-text-stroke: .5px $white;
				}
				&.black{
					-webkit-text-stroke: .5px $black;
				}
				@include tablet-up{
					font-size: 160px;
				}
				&.script{
					position: relative;
					display: block;
					max-width: max-content;
					margin: 0 auto 40px;
					&::after{
						content: "Lola";
						font-family: $script;
						color: $white;
						text-transform: capitalize;
						position: absolute;
						right: 0;
						bottom: -20px;
						font-size: 50px;
					}
					@include tablet-up{
						margin: 0 auto 80px;
						&::after{
							font-size: 120px;
							bottom: -40px;
							right: 5px;
						}
					}
				}
			}
		}

		@include tablet-up{
			padding: 120px 0;
		}


		&#hero{
			padding: 268px 0 0;
			.container{
				img{
					display: block;
					margin: 0 auto;
					max-width: 100%;
				}
			}

			@include tablet-up{
				padding-top: 228px;
			}
		}

		&#music{
			.container{
				.heading{
					text-align: center;
				}
				.music-grid{
					display: grid;
					gap: 20px;
					grid-template-columns: repeat(
						auto-fit,
						minmax(
							220px,
							1fr
						)
					);
					.music-item{
						display: block;
						width: 100%;
						text-align: center;
						h3{
							text-align: center;
							font-size: 28px;
							margin: 10px 0;
							font-family: $display;
							text-transform: uppercase;
							-webkit-text-stroke: 0.25px #fff;
							color: transparent;
							font-weight: 400;
						}
						img{
							width: 100%;
							max-height: 220px;
							object-fit: contain;
							object-position: center;
						}
					}
				}
			}

			@include tablet-up{
				.container{
					@include flex(row,justify-content,center);
					.heading{
						writing-mode: vertical-lr;
						transform: rotate(180deg);
						text-align: right;
						margin: 0;
					}
					.music-grid{
						padding: 0 30px;
						gap: 40px;
						flex: 1;
						.music-item{
							overflow: hidden;
							// max-width: 220px;
							img{
								transition: all .4s;
							}
							.wrapper{
								@include flex(row,space-between,center);
								// gap: 40px;
								>a{
									display: block;
									flex: 1;
									background-color: orange;
									img{
										max-height: unset;
									}
								}
								.content{
									flex: 1;
									text-align: center;
									h3{
										text-align: center;
										margin-bottom: 30px;
										font-size: 62px;
									}
								}
							}
							@include hover-focus{
								img{
									transform: scale(1.05);
								}
							}
						}
					}
				}
			}
		}

		&#about{
			.container{
				.split{
					.side{
						&:first-child{
							display: none;
						}
						.bio{
							p{
								margin-bottom: 20px;
								&:last-child{
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}

			@include tablet-up{
				.container{
					.split{
						gap: 80px;
						align-items: center;
						.side{
							flex: 1;
							max-width: max-content;
							&:first-child{
								display: block;
							}
							.heading{
								margin: 0 0 80px;
							}
							.bio{
								p{
									margin-bottom: 40px;
								}
							}
						}
					}
				}
			}
		}

		&#tour{
			.container{
				.split{
					.side{
						&:last-child{
							display: none;
						}
						.heading{
							text-align: center;
						}
						#events{
							@include flex(row,center,center);
							gap: 40px;
							.event{
								width: 100%;
								text-transform: uppercase;
								@include flex(column,center,center);
								text-align: center;
								gap: 10px;
								font-size: 16px;
								line-height: 1.4;
								.event-info{
									.event-artist{
										font-weight: 700;
									}
									.event-featuring{
										span{
											display: inline-block;
											&::after{
												content: ",";
												padding-right: 5px;
											}
											&:first-child{
												display: none;
											}
											&:last-child{
												&::after{
													display: none;
												}
											}
											&:nth-child(n + 4){
												display: none;
											}
										}
									}
								}
							}
						}
					}
				}
			}

			@include tablet-up{
				position: relative;
				.container{
					.split{
						.side{
							flex: 2;
							.heading{
								text-align: left;
								margin: 0 0 40px;
							}
							&:first-child{
								position: relative;
								z-index: 99;
							}
							&:last-child{
								flex: 1;
								display: block;
								img{
									position: absolute;
									bottom: 0;
									right: 0;
								}
							}
							#events{
								.event{
									@include flex(row,initial,center);
									gap: 20px;
									text-align: left;
									>div{
										flex: 1;
										.event-info{
											flex: 2;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		&#videos{
			.container{
				.featured-video{
					width: 100%;
					margin-bottom: 20px;
				}
				.videos-grid{
					display: grid;
					gap: 20px;
					grid-template-columns: repeat(
						auto-fit,
						minmax(
							340px,
							1fr
						)
					);
				}
			}
		}

		&#shop{
			position: relative;
			&::before{
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				bottom: 0;
				left: 0;
				background: rgb(0,0,0);
				background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.17690826330532217) 30%, rgba(0,0,0,0) 70%);
				opacity: .8;
			}
			.container{
				position: relative;
				h2{
					&::after{
						color: $black;
					}
				}
				.shop-wrapper{
					display: grid;
					row-gap: 40px;
					column-gap: 20px;
					grid-template-columns: repeat(
						auto-fit,
						minmax(
							320px,
							1fr
						)
					);
					.shop-item{
						text-align: center;
						@include flex(column,center,center);
						img{
							display: inline-block;
							max-width: 220px;
							width: 100%;
							aspect-ratio: 1/1;
							object-fit: contain;
						}
						p{
							font-size: 16px;
							text-transform: uppercase;
							color: $white;
						}
						a{
							&:first-of-type{
								margin-bottom: 15px;
							}
							&:last-of-type{
								margin-top: 15px;
							}
						}
					}
				}
			}
			@include tablet-up{
				padding: 160px;
				.container{
					.heading{
						margin-bottom: 120px;
					}
				}
			}
			@media screen and (max-width:1349px) {
				&::before{
					display: none;
				}
				.container{
					.shop-wrapper{
						.shop-item{
							p{
								color: $black;
							}
						}
					}
				}
			}
		}

		&#instagram{
			overflow: hidden;
			.container{
				.heading{
					text-align: center;
				}
				.music-grid{
					display: grid;
					gap: 20px;
					grid-template-columns: repeat(
						auto-fit,
						minmax(
							220px,
							1fr
						)
					);
					.music-item{
						display: block;
						width: 100%;
						img{
							width: 100%;
							max-height: 220px;
							object-fit: contain;
							object-position: center;
						}
					}
				}
			}

			@include tablet-up{
				.container{
					@include flex(row,justify-content,initial);
					.heading{
						writing-mode: vertical-lr;
						transform: rotate(180deg);
						text-align: right;
						margin: 0;
						font-size: 70px;
					}
					.music-grid{
						gap: 40px;
						flex: 1;
						.music-item{
							overflow: hidden;
							max-width: 220px;
							img{
								transition: all .4s;
							}
							@include hover-focus{
								img{
									transform: scale(1.05);
								}
							}
						}
					}
				}
			}
		}

	}

}

footer {
	padding: 20px 0;
	.container{
		text-align: center;
		>img{
			display: inline-block;
			max-width: 440px;
			width: 100%;
		}
		#newsletter{
			h3{
				font-size: 16px;
				font-family: $display;
				margin-bottom: 30px;
			}
			form{
				margin-bottom: 60px;
				.form-row{
					@include flex(row,center,initial);
					gap: 10px;
					input{
						background-color: transparent;
						border: solid 1px transparent;
						border-bottom-color: $white;
						color: $white;
						text-transform: uppercase;
						&::placeholder{
							color: $white;
						}
					}
					button{
						background-color: transparent;
						border: solid 1px transparent;
						border-bottom-color: $white;
						color: $white;
						text-transform: uppercase;
					}
				}
			}
		}
		.social{
			margin-bottom: 60px;
		}
		.copyright{
			p{
				font-size: 12px;
				letter-spacing: 1px;
				line-height: 1.6;
			}
		}
	}
}